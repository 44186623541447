//@ts-nocheck

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getCalendarBySpace, getAddOns, useLogin } from "./core/_requests";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs, { Dayjs } from "dayjs";
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ButtonGroup,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomInputCardNumber from "../components/CustomInputCardNumber";
import CustomInputExpiration from "../components/CustomCardExpiration";
import CustomCardCode from "../components/CustomCardCode";
import CustomTimeRange from "../components/CustomTimeRange";
import { useCreateOrders } from "./core/_requests";
import { Add_ons, Time, TimeRanges, TimeRange } from "./core/_models";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../components/PaymentForm";

const stripePromise = loadStripe(
  "pk_test_51PQQDVCQ7IHqd2ZlyN5i0ihwyAlbTeXAFt1Aq1gqGEPRX5Ae97gOUoo0zcRwlJ9jLZe3FXhKfouTU0pkbKj306O100tnnYieBe"
);
const styles = (theme: any) => ({
  textField: {
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
});

const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FBAD18",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F2F8",
    color: "#505050",
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: "0.02em",
    padding: theme.spacing(1, 3),
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledLoginCard = styled(Card)(({ theme }) => ({
  width: "calc(50vw - 20px)",
  height: "70vh",
  marginTop: "3em",
  marginRight: "20px",
  boxShadow: "0px 4px 8px 8px rgba(183, 184, 184, 0.25)",
  marginBottom: "3em",
  padding: "20px",
}));

const StyledPaymentCard = styled(Card)(({ theme }) => ({
  width: "calc(50vw - 20px)",
  height: "fit-content",
  marginTop: "3em",
  marginRight: "20px",
  boxShadow: "0px 4px 8px 8px rgba(183, 184, 184, 0.25)",
  marginBottom: "3em",
  padding: "20px",
}));

const CustomStep = styled(Step)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[200] : "#eaeaf0",
    borderTopWidth: 2,
    borderRadius: 1,
    marginTop: "8px",
  },
  "& .MuiStepLabel-root": {
    fontSize: "2.5rem",
  },
  "& .MuiStepIcon-root": {
    fontSize: "2.5rem",
  },
  "& .MuiStepLabel-label": {
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
  "& .MuiStepIcon-root.Mui-active, & .MuiStepIcon-root.Mui-completed": {
    color: "#FBAD18 !important",
  },
  "& .MuiStepIcon-text": {
    fill: "#ffffff",
  },
}));

const Product: React.FC = () => {
  const { productId } = useParams();
  const {
    isFetching,
    refetch,
    data: calendar,
  } = useQuery("calendar", () => getCalendarBySpace(1), {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  console.log("calendar", calendar);

  const { mutate: login, isLoading, error, data } = useLogin();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(new Date())
  );
  const [timeRanges, setTimeRanges] = useState([
    {
      fromTime: { hours: 10, minutes: 0, ampm: "AM" },
      toTime: { hours: 12, minutes: 0, ampm: "AM" },
    },
  ]);
  const [selectedAddons, setSelectedAddons] = useState<any>({});
  const [totalAddonsAmount, setTotalAddonsAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [warningMessage, setWarningMessage] = useState("");
  const [clientSecret, setClientSecret] = useState();

  const handleDateChange = (date: Dayjs | null) => setSelectedDate(date);

  const timeToMinutes = (time: Time) => {
    console.log("timeToMinutes", time);
    return time.hours * 60 + time.minutes + (time.ampm === "PM" ? 12 * 60 : 0);
  };

  const checkForConflicts = (newTimeRanges: TimeRange) => {
    const hasConflict = newTimeRanges.some((timeRange, index) => {
      return newTimeRanges.some((otherTimeRange, otherIndex) => {
        if (index !== otherIndex) {
          const fromInMinutes = timeToMinutes(timeRange.fromTime);
          const toInMinutes = timeToMinutes(timeRange.toTime);
          const otherFromInMinutes = timeToMinutes(otherTimeRange.fromTime);
          const otherToInMinutes = timeToMinutes(otherTimeRange.toTime);
          return (
            (fromInMinutes >= otherFromInMinutes &&
              fromInMinutes < otherToInMinutes) ||
            (toInMinutes > otherFromInMinutes &&
              toInMinutes <= otherToInMinutes) ||
            (fromInMinutes <= otherFromInMinutes &&
              toInMinutes >= otherToInMinutes)
          );
        }
        return false;
      });
      // || !isTimeAvailable(timeRange.fromTime, timeRange.toTime)
    });

    if (hasConflict) {
      setWarningMessage("The selected time range is not available.");
    } else {
      setWarningMessage("");
    }
  };

  const handleTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: "from" | "to",
    field: "hours" | "minutes"
  ) => {
    const value = parseInt(e.target.value, 10);
    setTimeRanges((prev) => {
      const newTimeRanges = [...prev];
      console.log("newTimeRanges", newTimeRanges);
      if (type === "from") {
        newTimeRanges[index].fromTime[field] = value;
      } else {
        newTimeRanges[index].toTime[field] = value;
      }
      checkForConflicts(newTimeRanges);
      return newTimeRanges;
    });
  };

  const handleAmpmChange = (
    index: number,
    type: "from" | "to",
    value: "AM" | "PM"
  ) => {
    setTimeRanges((prev) => {
      const newTimeRanges = [...prev];
      if (type === "from") {
        newTimeRanges[index].fromTime.ampm = value;
      } else {
        newTimeRanges[index].toTime.ampm = value;
      }
      checkForConflicts(newTimeRanges);
      return newTimeRanges;
    });
  };

  const handleAddonChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    addonId: number
  ) => {
    const value = parseInt(e.target.value, 10) || 0;
    setSelectedAddons((prev: Array<number>) => ({
      ...prev,
      [addonId]: value,
    }));
    console.log("selected addons", selectedAddons);
  };

  const calculateTotalAddonsAmount = () => {
    if (!calendar?.add_ons) return 0;
    return Object.keys(selectedAddons).reduce((total, addonId) => {
      const addon = calendar?.add_ons.find(
        (addon) => addon.id === parseInt(addonId)
      );
      if (addon) {
        return total + addon.price * selectedAddons[addonId];
      }
      return total;
    }, 0);
  };

  useEffect(() => {
    setTotalAddonsAmount(calculateTotalAddonsAmount());
  }, [selectedAddons]);

  const handleInputChangeTime = (
    time: string,
    index: number,
    type: "from" | "to"
  ) => {
    const parsedTime = parseTime(time);
    setTimeRanges((prev) => {
      const newTimeRanges = [...prev];
      if (type === "from") {
        newTimeRanges[index].fromTime = parsedTime;
      } else {
        newTimeRanges[index].toTime = parsedTime;
      }
      return newTimeRanges;
    });
  };

  const calculateTotalTime = () => {
    return timeRanges.reduce((total, timeRange) => {
      const fromInMinutes =
        timeRange.fromTime.hours * 60 +
        timeRange.fromTime.minutes +
        (timeRange.fromTime.ampm === "PM" ? 12 * 60 : 0);
      const toInMinutes =
        timeRange.toTime.hours * 60 +
        timeRange.toTime.minutes +
        (timeRange.toTime.ampm === "PM" ? 12 * 60 : 0);
      return total + (toInMinutes - fromInMinutes) / 60;
    }, 0);
  };

  const getHourRateForDay = (day: string, timeRange: any) => {
    const rateInfo = calendar?.hour_rates?.find((rate) => rate.day === day);
    if (!rateInfo) return 0;

    const fromTime = parseTime(rateInfo.from);
    const toTime = parseTime(rateInfo.until);
    const fromInMinutes =
      fromTime.hours * 60 +
      fromTime.minutes +
      (fromTime.ampm === "PM" ? 12 * 60 : 0);
    const toInMinutes =
      toTime.hours * 60 + toTime.minutes + (toTime.ampm === "PM" ? 12 * 60 : 0);

    const bookedFromInMinutes =
      timeRange.fromTime.hours * 60 +
      timeRange.fromTime.minutes +
      (timeRange.fromTime.ampm === "PM" ? 12 * 60 : 0);
    const bookedToInMinutes =
      timeRange.toTime.hours * 60 +
      timeRange.toTime.minutes +
      (timeRange.toTime.ampm === "PM" ? 12 * 60 : 0);

    if (
      bookedFromInMinutes >= fromInMinutes &&
      bookedToInMinutes <= toInMinutes
    ) {
      return rateInfo.rate;
    }

    return 0;
  };

  const parseTime = (time: string) => {
    const [hoursMinutes, ampm] = time.split(" ");
    const [hours, minutes] = hoursMinutes.split(":").map(Number);
    return { hours, minutes, ampm };
  };

  // const isTimeAvailable = (fromTime, toTime) => {
  //   const fromMinutes = timeToMinutes(fromTime);
  //   const toMinutes = timeToMinutes(toTime);

  //   for (const slot of Object.values(calendar.availability)) {
  //     const slotFrom = parseTime(slot.start);
  //     const slotTo = parseTime(slot.end);
  //     const slotFromMinutes = timeToMinutes(slotFrom);
  //     const slotToMinutes = timeToMinutes(slotTo);

  //     if (
  //       (fromMinutes >= slotFromMinutes && fromMinutes < slotToMinutes) ||
  //       (toMinutes > slotFromMinutes && toMinutes <= slotToMinutes) ||
  //       (fromMinutes <= slotFromMinutes && toMinutes >= slotToMinutes)
  //     ) {
  //       if (!slot.available) {
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // };

  const calculateTotalPrice = () => {
    if (!selectedDate) return 0;

    const day = selectedDate.format("dddd");
    console.log("day", day);
    const totalHours = calculateTotalTime();
    const hourRate = timeRanges.reduce((total, timeRange) => {
      return total + getHourRateForDay(day, timeRange);
    }, 0);
    const hoursPrice = totalHours * hourRate;
    console.log("total hours", totalHours);
    console.log("hourRate", hourRate);
    return hoursPrice + totalAddonsAmount;
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
    console.log("total price", totalPrice);
  }, [selectedDate, timeRanges, totalAddonsAmount]);

  const addTimeRange = () => {
    setTimeRanges((prev) => [
      ...prev,
      {
        fromTime: { hours: 10, minutes: 0, ampm: "AM" },
        toTime: { hours: 12, minutes: 0, ampm: "PM" },
      },
    ]);
  };

  const removeTimeRange = (index: number) => {
    setTimeRanges((prev) => {
      const newTimeRanges = [...prev];
      newTimeRanges.splice(index, 1);
      checkForConflicts(newTimeRanges);
      return newTimeRanges;
    });
  };

  const steps = [
    "Select Date",
    "Select Add-Ons",
    "Summary",
    "Checkout",
    "Payment",
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  console.log("time ranges:", timeRanges);

  const [formInput, setFormInput] = useState({
    d_client_id: "",
    d_space_id: "",
    payment_method: "",
    d_client_card_id: "",
    d_client_card_number: "",
    d_client_card_expiration: "",
    d_client_card_cvc: "",
    first_amount: 10,
    final_amount: totalPrice.toFixed(2) ? totalPrice.toFixed(2) : 0,
    d_operator_id: "",
    d_unit_id: "",
    state: "",
    add_ons_id: [],
    add_ons_quantity: [],
    time_ranges: timeRanges,
    client_email: "",
    client_password: "",
  });

  const [formErrors, setFormErrors] = useState(false);
  const createOrdersMutation = useCreateOrders();

  const handleChange = (key: string, value: string) => {
    setFormInput({
      ...formInput,
      [key]: value,
    });
  };

  const onSubmit = async () => {
    try {
      // await createOrdersMutation.mutateAsync(formInput);
      console.log("Orders", formInput);
    } catch (error) {
      console.log("error", error);
    }
  };

  const SummaryCard: React.FC<{
    selectedDate: Dayjs | null;
    timeRanges: any[];
  }> = ({ selectedDate, timeRanges }) => (
    <Card
      sx={{
        width: "calc(30vw - 20px)",
        marginRight: "20px",
        boxShadow: "0px 4px 8px 8px rgba(183, 184, 184, 0.25)",
        marginBottom: "3em",
      }}
    >
      <CardContent
        sx={{
          padding: "0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          backgroundImage: `url(/assets/background/Rectangle9.png)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundBlendMode: "normal",
        }}
      >
        <div className="date-container">
          <Typography
            variant="h6"
            sx={{ mb: 1, fontWeight: 600 }}
            color="text.secondary"
          >
            {selectedDate?.format("MMMM DD, YYYY")}
          </Typography>
        </div>
        <div className="time-range-container">
          {timeRanges.map((timeRange, index) => (
            <Typography
              variant="h6"
              key={index}
              sx={{ mb: 1, fontWeight: 600 }}
              color="white"
            >
              {timeRange.fromTime.hours}:{timeRange.fromTime.minutes}
              {timeRange.fromTime.ampm} - {timeRange.toTime.hours}:
              {timeRange.toTime.minutes} {timeRange.toTime.ampm}
            </Typography>
          ))}
        </div>
      </CardContent>
    </Card>
  );
  const options = { clientSecret };

  const LoginCard: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { mutate: login, isLoading, error, data } = useLogin();

    useEffect(() => {
      if (data) {
        setClientSecret(data.result.client_secret);
        console.log("Client Data:", data.result.client_secret);
      }
    }, [data]);

    const handleSubmit = (event) => {
      event.preventDefault();
      login({ email, password });
    };

    return (
      <StyledLoginCard>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "80%",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, mb: 3, color: "#FBAD18" }}
          >
            Login
          </Typography>
          <CustomTextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            // onChange={(e) => {
            //   handleChange("client_email", e.target.value);
            // }}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2, color: "#FBAD18" }}
            InputProps={{
              sx: {
                borderRadius: "8px",
              },
            }}
          />

          <CustomTextField
            label="Password"
            variant="outlined"
            fullWidth
            type="password"
            value={password}
            // onChange={(e) => {
            //   handleChange("client_password", e.target.value);
            // }}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                borderRadius: "8px",
              },
            }}
          />
          <Button
            variant="outlined"
            fullWidth
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#FBAD18",
              color: "#ffffff",
              width: "40%",
              borderRadius: "8px",
              borderColor: "#FBAD18",
              "&:hover": {
                color: "#fff",
                cursor: "pointer",
                backgroundColor: "#fcc65d",
                borderColor: "#FBAD18",
                transform: "translateY(-1px)",
                boxShadow:
                  "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08),",
              },
            }}
          >
            Sign Up
          </Button>
        </CardContent>
      </StyledLoginCard>
    );
  };

  const PaymentCard: React.FC = () => {
    const [cardNumber, setCardNumber] = useState("");
    const [expiration, setExpiration] = useState("");
    const [cvc, setCvc] = useState("");

    const handleCvcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCvc(e.target.value);

      // handleChange("d_client_card_cvc", cvc);
    };
    const handleExpirationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setExpiration(e.target.value);
      // handleChange("d_client_card_expiration", expiration);
    };
    const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCardNumber(e.target.value);
    };

    const handleOrderButtonClick = () => {
      console.log("Order button clicked");
    };
    return (
      <StyledPaymentCard>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, mb: 3, color: "#FBAD18" }}
          >
            Payment
          </Typography>
          <Elements options={clientSecret} stripe={stripePromise}>
            <PaymentForm totalPrice={totalPrice && totalPrice} />
          </Elements>

          {/* <Button
            variant="outlined"
            fullWidth
            onClick={onSubmit}
            sx={{
              backgroundColor: "#FBAD18",
              color: "#ffffff",
              width: "40%",
              borderRadius: "8px",
              borderColor: "#FBAD18",
              marginTop: "1em",
              "&:hover": {
                color: "#fff",
                cursor: "pointer",
                backgroundColor: "#fcc65d",
                borderColor: "#FBAD18",
                transform: "translateY(-1px)",
                boxShadow:
                  "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08),",
              },
            }}
          >
            Order
          </Button> */}
        </CardContent>
      </StyledPaymentCard>
    );
  };

  return (
    <div className="booking-component">
      <h2>Booking (Meeting Room Number)</h2>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <CustomStep key={label}>
            <StepLabel>{label}</StepLabel>
          </CustomStep>
        ))}
      </Stepper>
      <div className="selection-container">
        {activeStep === 0 && (
          <>
            <div className="date-picker">
              <h3>Select Date</h3>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="de"
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={selectedDate}
                  onChange={handleDateChange}
                  disablePast
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    "& .Mui-selected": {
                      backgroundColor: "#FBAD18 !important",
                      color: "#ffffff",
                    },
                    "& .Mui-selected:hover": {
                      backgroundColor: "#FBAD18",
                    },
                    "& .MuiDayPicker-monthContainer": {
                      "& .MuiPickersDay-dayOutsideMonth": {
                        color: "#dedede",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="time-picker">
              <h3>Select Time</h3>
              {timeRanges.map((timeRange, index) => (
                <div key={index} className="time-inputs">
                  <div className="time-input">
                    <label>From:</label>
                    <div className="time-selectors">
                      <TextField
                        variant="outlined"
                        type="number"
                        value={timeRange.fromTime.hours}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleTimeChange(e, index, "from", "hours")
                        }
                        InputProps={{
                          inputProps: { min: 1, max: 12 },
                          sx: {
                            background:'transparent !important',
                            borderRadius: 2,
                            height: "40px",
                            width: "65px",
                            marginRight: "5px",
                          },
                        }}
                      />
                      :
                      <TextField
                        variant="outlined"
                        type="number"
                        value={timeRange.fromTime.minutes}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleTimeChange(e, index, "from", "minutes")
                        }
                        InputProps={{
                          inputProps: { min: 0, max: 59 },
                          sx: {
                            borderRadius: 2,
                            height: "40px",
                            width: "65px",
                            marginLeft: "5px",
                          },
                        }}
                      />
                      <ButtonGroup
                        variant="contained"
                        disableElevation
                        aria-label="AM/PM selection"
                        sx={{ marginLeft: 1 }}
                      >
                        <Button
                          onClick={() => handleAmpmChange(index, "from", "AM")}
                          sx={{
                            backgroundColor:
                              timeRange.fromTime.ampm === "AM"
                                ? "#FBAD18"
                                : "#f0f0f0",
                            color:
                              timeRange.fromTime.ampm === "AM"
                                ? "#ffffff"
                                : "#666666",
                            borderColor: "#666666",
                            "&:hover": {
                              backgroundColor:
                                timeRange.fromTime.ampm === "AM"
                                  ? "#FBAD18"
                                  : "#f0f0f0",
                              borderColor: "#FBAD18",
                            },
                          }}
                        >
                          AM
                        </Button>
                        <Button
                          onClick={() => handleAmpmChange(index, "from", "PM")}
                          sx={{
                            backgroundColor:
                              timeRange.fromTime.ampm === "PM"
                                ? "#FBAD18"
                                : "#f0f0f0",
                            color:
                              timeRange.fromTime.ampm === "PM"
                                ? "#ffffff"
                                : "#666666",
                            borderColor: "#666666",
                            "&:hover": {
                              backgroundColor:
                                timeRange.fromTime.ampm === "PM"
                                  ? "#FBAD18"
                                  : "#f0f0f0",
                              borderColor: "#FBAD18",
                            },
                          }}
                        >
                          PM
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <div className="time-input">
                    <label>To:</label>
                    <div className="time-selectors">
                      <TextField
                        variant="outlined"
                        type="number"
                        value={timeRange.toTime.hours}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleTimeChange(e, index, "to", "hours")
                        }
                        InputProps={{
                          inputProps: { min: 1, max: 12 },
                          sx: {
                            borderRadius: 2,
                            height: "40px",
                            width: "65px",
                            marginRight: "5px",
                          },
                        }}
                      />
                      :
                      <TextField
                        variant="outlined"
                        type="number"
                        value={timeRange.toTime.minutes}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleTimeChange(e, index, "to", "minutes")
                        }
                        InputProps={{
                          inputProps: { min: 0, max: 59 },
                          sx: {
                            borderRadius: 2,
                            height: "40px",
                            width: "65px",
                            marginLeft: "5px",
                          },
                        }}
                      />
                      <ButtonGroup
                        variant="contained"
                        disableElevation
                        aria-label="AM/PM selection"
                        sx={{ marginLeft: 1 }}
                      >
                        <Button
                          onClick={() => handleAmpmChange(index, "to", "AM")}
                          sx={{
                            backgroundColor:
                              timeRange.toTime.ampm === "AM"
                                ? "#FBAD18"
                                : "#f0f0f0",
                            color:
                              timeRange.toTime.ampm === "AM"
                                ? "#ffffff"
                                : "#666666",
                            borderColor: "#666666",
                            "&:hover": {
                              backgroundColor:
                                timeRange.toTime.ampm === "AM"
                                  ? "#FBAD18"
                                  : "#f0f0f0",
                              borderColor: "#FBAD18",
                            },
                          }}
                        >
                          AM
                        </Button>
                        <Button
                          onClick={() => handleAmpmChange(index, "to", "PM")}
                          sx={{
                            backgroundColor:
                              timeRange.toTime.ampm === "PM"
                                ? "#FBAD18"
                                : "#f0f0f0",
                            color:
                              timeRange.toTime.ampm === "PM"
                                ? "#ffffff"
                                : "#666666",
                            borderColor: "#666666",
                            "&:hover": {
                              backgroundColor:
                                timeRange.toTime.ampm === "PM"
                                  ? "#FBAD18"
                                  : "#f0f0f0",
                              borderColor: "#FBAD18",
                            },
                          }}
                        >
                          PM
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  {timeRanges.length > 1 && (
                    <Button
                      key={index}
                      variant="outlined"
                      onClick={() => removeTimeRange(index)}
                      sx={{
                        borderColor: "#FBAD18",
                        backgroundColor: "transparent",
                        marginTop: 2,
                        alignSelf: "flex-start",
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        minWidth: "30px",
                        padding: 0,
                        color: "#FBAD18",
                        "&:hover": {
                          backgroundColor: "#ffffff",
                          borderColor: "#FBAD18",
                        },
                      }}
                    >
                      -
                    </Button>
                  )}
                </div>
              ))}
              {warningMessage && (
                <p className="warning-message">{warningMessage}</p>
              )}
              <Button
                variant="outlined"
                onClick={addTimeRange}
                sx={{
                  borderColor: "#FBAD18",
                  color: "#FBAD18",
                  marginTop: 2,
                  alignSelf: "flex-start",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#FBAD18",
                  },
                }}
              >
                +
              </Button>

              {/* <CustomTimeRange
                availability={calendar?.availability}
                detailsUnit={calendar?.units}
                handleInputChangeTime={handleInputChangeTime}
              /> */}

              <div className="total-time">
                <span className="time-label">Total Time Selected:</span>
                <span className="total-hours">
                  {calculateTotalTime()} Hours
                </span>
              </div>
            </div>
          </>
        )}
        {activeStep === 1 && (
          <div className="addons-picker">
            <SummaryCard selectedDate={selectedDate} timeRanges={timeRanges} />
            <div className="addons-right-side">
              <h3>Select Add-Ons</h3>
              <TableContainer
                component={Paper}
                sx={{
                  marginTop: "20px",
                  width: "60vw",
                  alignSelf: "flex-end",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Unit</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {calendar?.add_ons?.map((addon) => (
                      <TableRow key={addon.id}>
                        <TableCell>
                          <TextField
                            type="number"
                            variant="outlined"
                            className="custom-input"
                            value={selectedAddons[addon.id] || 0}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleAddonChange(e, addon.id)}
                            inputProps={{
                              min: 0,
                              sx: {
                                borderRadius: 2,
                                height: "10px",
                                width: "45px",
                                marginLeft: "5px",
                              },
                            }}
                            sx={{
                              borderRadius: 2,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {addon.entitled} - {addon.price}/booking
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="total-amount">
                <span className="amount-label">Total :</span>
                <span className="total-price">
                  {totalAddonsAmount.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        )}
        {activeStep === 2 && (
          <div className="summary">
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "20px",
                width: "60vw",
                alignSelf: "flex-end",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Time</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {selectedDate?.format("MMMM DD, YYYY")}
                    </TableCell>
                    <TableCell>{calculateTotalTime()} hours</TableCell>
                    <TableCell>{totalPrice.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div className="total-time">
              <span className="time-label">Total:</span>
              <span className="total-hours">{totalPrice.toFixed(2)}</span>
            </div>
          </div>
        )}

        {activeStep === 3 && <LoginCard />}
        {activeStep === 4 && <PaymentCard />}
      </div>
      <div className="stepper-buttons">
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{
            borderColor: "#FBAD18 !important",
            color: "#FBAD18",
            marginRight: 2,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={activeStep === 4}
          onClick={handleNext}
          sx={{
            backgroundColor: "#FBAD18 !important",
            color: "#ffffff",
          }}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Product;

import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Calendar, Credentials } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

const getCalendarBySpace = async (spaceId: number): Promise<Calendar> => {
  const response = await axios.get(
    `${API_URL}/calendar/${spaceId}?date=2024-06-10`
  );
  return response.data;
};

const getAddOns = async (): Promise<Calendar> => {
  const response = await axios.get(`${API_URL}/add-ons`);
  return response.data;
};

const ClientLogin = async () => {
  const response = await axios.post(`${API_URL}/client-login`);
  return response.data;
};

const getClientSecret = async (): Promise<string> => {
  const response = await axios.post(`${API_URL}/checkout`);
  return response.data.clientSecret;
};

const useCreateOrders = async () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (values) => {
      return axios.post(`/orders`, values);
    },

    onSuccess: (data) => {
      console.log(data?.data?.message);
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    onError: (data) => {
      // console.log(data?.data?.message);
    },
  });

  return mutation;
};

const login = async (credentials: Credentials) => {
  const response = await axios.post(`${API_URL}/client-login`, credentials);
  return response.data;
};

export const useLogin = () => {
  return useMutation(login);
};

export {
  getCalendarBySpace,
  getAddOns,
  useCreateOrders,
  getClientSecret,
  ClientLogin,
};
